import React from "react";
import serialize from "form-serialize";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class Form extends React.PureComponent {
    render() {
        const { props } = this;
        const { children, id, autoComplete = true } = props;
        const classes = createClassName(props.className, {
            "Form": true,
        });

        return (
            <form ref={ref => this.form = ref}
                  className={classes}
                  id={id}
                  onKeyPress={this._onKeyPress}
                  onSubmit={(e) =>
                      this._onSubmit(e, {triggeredFrom: null, viewIndex: null}) // Bind extra props directly here
                  }
                  autoComplete={autoComplete === true ? "on" : "off"}>
                <div className="Form-container">
                    {children}
                </div>
            </form>
        );
    }

    // Internal methods
    _onKeyPress = (e) => {
        const { props } = this;
        const { key } = e;
        
        if(key === "Enter" && e.target.nodeName !== "TEXTAREA") {
            e.preventDefault();
            if(props.submitOnEnter === true) this._onSubmit();
        }
    }

    _onSubmit = (e, additionalData) => {
        const { defaultFieldValue, onSubmit } = this.props;
        
        if(e) e.preventDefault();
        if(onSubmit) {
            const { form } = this;
            const result = serialize(form, {
                hash: true,
                empty: true,
            });
            
            // Loop through result to set default values
            for(const key in result) {
                let value = result[key];
                
                if(typeof value === "object") value = value[value.length-1];
                if(typeof value === "undefined" || value === "") value = defaultFieldValue;

                result[key] = value;
            }

            onSubmit(result, additionalData);
        }
    }
};