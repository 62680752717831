import React from "react";

import TextField from "@components/TextField/TextField";
import Validator from "@classes/Validator";

const identityNoInputField = props => {
    const {
      name,
      value,
      label,
      placeholder,
      onChangeCallback,
      readOnly,
      disabled,
      style,
      personsOnly,
      companiesOnly
    } = props;

    if (personsOnly && companiesOnly) {
      console.error("confilcting props: personsOnly and companiesOnly can't be true at the same time.");
    }

    const onChange = e => {
      const input = e.target;
      const value = input.value;
      const valueCameFromPaste = ((input.getAttribute("pasted") || "") == "1");
      
      const validated = Validator.validateSsn(value, { personsOnly, companiesOnly });
      
      input.setAttribute("pasted", "0")        
      return onChangeCallback(value, validated, valueCameFromPaste);
    }
    
    const validation = value => Validator.validateSsn(value, { personsOnly, companiesOnly });

    return (
      <TextField
        name={name}
        value={value}
        autocomplete="off"
        label={label}
        placeholder={placeholder}
        validation={validation}
        onChange={onChangeCallback && onChange}
        readOnly={readOnly}
        disabled={disabled}
        style={{ ...style, maxWidth: "unset" }}
      />
    )
}

export default identityNoInputField;
