import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	// Om kunden är företag måste minst en signatär anges
	if (customerState.isCompany)
	{
		for (let i = 0; i < customerState.company_active_signatories; i++)
		{

			const email = customerState['company_signatory_email_' + i];
			const ssn = customerState['company_signatory_sec_num_' + i];

			if (customerState['company_signatory_firstname_' + i].length === 0 ||
				customerState['company_signatory_lastname_' + i].length === 0 ||
				email.length === 0 ||
				ssn.length === 0)
			{
				return false;
			}

			// kolla att email är OK
			if (!validateEmail(email))
			{
				return false;
			}

			// kolla att personnr är rätt format
			if (isNaN(ssn) || !(ssn.length === 12 && (ssn.substring(0, 2) === "19" || ssn.substring(0, 2) === "20")))
			{
				return false;
			}
		}
	}

	if (customerState.customer_source.length <= 0)
	{
		return false;
	}

	if (customerState.customer_source === "Annat" && customerState.customer_source_other_text.length <= 0)
	{
		return false;
	}

	if (customerState.no_placement_done)
	{
		return true;
	}

	/**
	 * The following applies only if a placement has been done
	 */

	if (customerState.commission_sharing_number_of_advisors <= 0 || customerState.commission_sharing_number_of_advisors > 4)
	{
		return false;
	}

	let totalShare = 0;
	for (let i = 1; i <= customerState.commission_sharing_number_of_advisors; i++)
	{
		const advisorName = customerState["commission_sharing_advisor_name_" + i];
		const advisorShare = customerState["commission_sharing_advisor_share_" + i];

		if (advisorName.length <= 0 || advisorShare.length <= 0 || advisorShare > 100 || advisorShare < 0 || isNaN(advisorShare))
		{
			return false;
		}

		totalShare += parseFloat(advisorShare);
	}

	if (totalShare !== 100)
	{
		return false;
	}

	return !!(
		customerState.advice_sign_row_1 === "Ja" &&
		customerState.advice_sign_row_2 === "Ja" &&
		customerState.advice_sign_row_3 === "Ja" &&
		customerState.advice_sign_row_4 === "Ja" &&
		customerState.advice_sign_row_5 === "Ja" &&
		customerState.advice_sign_row_6 === "Ja" &&
		customerState.advice_sign_row_7 === "Ja" &&
		customerState.advice_sign_row_8 === "Ja"
	);
}

function validateEmail(email)
{
	// eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}