import React from "react";

import Validator from "@classes/Validator";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Checkbox from "@components/Checkbox/Checkbox";
import Button from "@components/Button/Button";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import Modal from "@components/Modal/Modal";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import fulfillment from "./CustomerInformation.fulfillment";
import CountrySelection from "../../../components/CountrySelection/CountrySelection";
import Select from "../../../../../components/Select/Select";
import CompanyReps from "../../CustomerInformation/subcomponents/CompanyReps/CompanyReps";
import {loop} from "../../../../../helpers/utils";
import IdentityDocument from "../../../components/IdentityDocument/IdentityDocument";
import DatePicker from "react-datepicker";
import PartnerHiddenFields from "../../../components/PartnerHiddenFields/PartnerHiddenFields";

export default class CustomerInformation extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setLookupInfo(lookupInfo)
	{
		const {props, state} = this;
		const {customerState} = state;
		const {isCompany} = props.customer;

		this.setState({docsFulfilled: props.customer.hasValidIdentityDocument()}, () =>
		{
			this.setCustomerState({
				firstName: customerState.firstName || lookupInfo[isCompany ? "companyName" : "firstName"] || "",
				lastName: isCompany ? "" : (customerState.lastName || lookupInfo.lastName || ""),
				street: customerState.street || lookupInfo.street || "",
				zipCode: customerState.zipCode || lookupInfo.zipCode || "",
				city: customerState.city || lookupInfo.city || "",
				email: customerState.email || lookupInfo.email || "",
				phoneNumber: customerState.phoneNumber || "",
				phoneNumberHome: customerState.phoneNumberHome || lookupInfo.phoneNumberHome || "",
				phoneNumberAlternative: customerState.phoneNumberAlternative || "",
				leiCode: customerState.phoneNumberHome || lookupInfo.client.lei_code || "",
			});
		});
	}

	async componentDidMount()
	{
		const {props} = this;
		const {customer, history, setAppState} = props;
		const ssnOrg = customer.getData("customer_social_security_number", null) || customer.getData("customer_ssn", null);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {disabled: true},
				next: {}
			}
		});

		if (!ssnOrg)
		{
			history.push("/cob/1");
			return;
		}

		if (!customer.lookupInfo)
		{
			try
			{
				await customer.lookupAsync();
				const lookupNotFound = customer.lookupInfo.type === undefined;
				this.setState({isLoading: false, lookupNotFound}, () => this.setLookupInfo(customer.lookupInfo));
			}
			catch (e)
			{
				if (e.message === 'FRAUD_FLAG')
				{
					console.warn("Customer information locked. Case can not be completed.");
					this.setState({isLoading: false, lookupNotFound: true, fraudFlag: true});
				}
				else
				{
					console.warn("Customer information not found. Information must be entered manually.");
					this.setState({isLoading: false, lookupNotFound: true, fraudFlag: false});
				}
			}
		}
		else
		{
			this.setState({isLoading: false}, () => this.setLookupInfo(customer.lookupInfo));
		}
	}

	render()
	{
		const {props, state} = this;
		const {user, customer, onRestartSession} = props;
		const {customerState, lookupNotFound} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		if (state.isLoading)
		{
			return <ActivityIndicator busy/>;
		}

		let ssnOrg = customerState.ssnOrg;
		if (ssnOrg)
		{
			if (ssnOrg.length === 12)
			{
				ssnOrg = ssnOrg.substring(0, 8) + "-" + ssnOrg.substring(8);
			}
			else if (ssnOrg.length === 10)
			{
				ssnOrg = ssnOrg.substring(0, 6) + "-" + ssnOrg.substring(6);
			}
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>
				<PartnerHiddenFields customer={customer} user={user} />
				<input type="hidden" name="customer_phonenumber_home" value={customerState.phoneNumberHome || "-"}/>
				<input type="hidden" name="customer_phonenumber_cell" value={customerState.phoneNumber || "-"}/>
				<input type="hidden" name="customer_phonenumber_alt" value={customerState.phoneNumberAlternative || "-"}/>
				<input type="hidden" name="customer_classification" value="Icke-professionell kund"/>
				<input type="hidden" name="radio_customer_type" value={customer.isCompany ? "company" : "person"}/>

				<Modal visible={state.fraudFlag} status="danger" title="Personnummer låst">
					<React.Fragment>
						<p>
							Kundens personnummer är låst via en extern ID-spärr. Vänligen kontakta backoffice.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>{i18n("cob", "customer_information")}</h2>

					<div className="Cob-view2-fields">
						<h3>{i18n("customer_information", "meeting_information")}</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<strong style={{marginBottom: "8px"}}>{i18n("customer_information", "consultation_date")}</strong>
								<DatePicker
									placeholderText={i18n("customer_information", "select_date")}
									calendarStartDay={1}
									selected={customerState.date_object}
									dateFormat="yyyy-MM-dd"
									maxDate={new Date()}
									className={customerState.date.length > 0 ? "datepicker-valid" : ""}
									onChange={date => this.setCustomerState({
											date_object: date,
											date: (new Intl.DateTimeFormat("sv-SE", {
												year: "numeric",
												month: "2-digit",
												day: "2-digit"
											}).format(date))
										})}/>
								<input type={"hidden"} name={"date"} value={customerState.date}/>
							</div>
							<div>
								<strong style={{marginBottom: "8px"}}>{i18n("customer_information", "consultation_execution")}*</strong>
								<Select
									label={i18n("customer_information", "select_meeting_format")}
									selectedIndex={customerState.meetingFormatIndex}
									options={[
										{label: i18n("customer_information", "physical_meeting"), value: "Fysiskt möte"},
										{label: i18n("customer_information", "digital_meeting"), value: "Digitalt möte"},
										{label: i18n("customer_information", "phone_meeting"), value: "Telefonmöte"}
									]}
									onChange={(option, meetingFormatIndex) => this.setCustomerState({
										meetingFormat: option.value,
										meetingFormatIndex
									})}
								/>

								<input type="hidden" name="radio_meeting_type" value={customerState.meetingFormat}/>
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						<h3>{customer.isCompany ? i18n("customer_information", "company_details") : i18n("customer_information", "basic_information")}</h3>

						{lookupNotFound && !customer.isCompany && (
							<React.Fragment>
								<StackLayout orientation="vertical" alignItems="start">
									<Checkbox
										name="checkbox_customer_foreigner"
										label={i18n("cob", "lives_overseas_foreign_citizen")}
										checked={customerState.isForeigner}
										onChange={isForeigner => this.setCustomerState({isForeigner})}
									/>

									{customerState.isForeigner && (
										<Checkbox
											style={{marginTop: 10}}
											label={i18n("customer_information", "resides_in_the_eu")}
											checked={customerState.isNonEuPerson}
											onChange={
												isNonEuPerson => this.setState({
													errorMessage: `${i18n("customer_information", "outside_eu_text")}.`
												}, () => this.setCustomerState({isNonEuPerson}))
											}
										/>
									)}
								</StackLayout>
							</React.Fragment>
						)}

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>

							<div>
								<TextField
									name="customer_firstname"
									value={customerState.firstName}
									autocomplete="off"
									label={i18n("general", customer.isCompany ? "organization_name" : "first_name")}
									validation={lookupNotFound ? value => value.length > 0 : undefined}
									onChange={lookupNotFound ? ({target}) => this.setCustomerState({firstName: target.value}) : undefined}
									readOnly={!lookupNotFound}
									required={lookupNotFound}
								/>
							</div>

							{!customer.isCompany && (
								<div>
									<TextField
										name="customer_lastname"
										value={customerState.lastName}
										autocomplete="off"
										label={i18n("general", "last_name")}
										validation={lookupNotFound ? value => value.length > 0 : undefined}
										onChange={lookupNotFound ? ({target}) => this.setCustomerState({lastName: target.value}) : undefined}
										required={lookupNotFound}
									/>
								</div>
							)}

							<div>
								<TextField
									name="customer_ssn"
									autocomplete="off"
									value={customerState.ssnOrg}
									label={i18n("general", customer.isCompany ? "organization_number" : "social_security_number")}
									readOnly
								/>
							</div>

							{customer.isCompany && (
								<div>
									<TextField
										name="customer_lei"
										label={i18n("customer_information", "lei_code")}
										autocomplete="off"
										value={customerState.leiCode}
										validation={Validator.validateLeiCode}
										onChange={({target}) => this.setCustomerState({leiCode: target.value})}
									/>
								</div>
							)}
						</StackLayout>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="customer_address_street"
									value={customerState.street}
									label={i18n("general", "street_address")}
									autocomplete="off"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({street: target.value})}
									required={true}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_zipcode"
									value={customerState.zipCode}
									label={i18n("general", "zip_code")}
									autocomplete="off"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({zipCode: target.value})}
									required={true}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_city"
									value={customerState.city}
									label={i18n("general", "city")}
									autocomplete="off"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({city: target.value})}
									required={true}
								/>
							</div>
							<div>
								<CountrySelection label={i18n("customer_information", "country")}
												  field={"customer_address_country"}
												  selected={customerState.country}
												  mandatory={true}
												  onChange={(value) => this.setCustomerState({country: value.label})}/>
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						{!customer.isCompany && (
							<React.Fragment>
								<h3>{i18n("customer_information", "contact_details")}</h3>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<TextField
											name="customer_phonenumber_cell_input"
											value={customerState.phoneNumber === "-" ? "" : customerState.phoneNumber}
											label={i18n("customer_information", "phone")}
											autocomplete="off"
											validation={Validator.validatePhoneNum}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumber: target.value === "" ? "-" : target.value})}
											required={true}
										/>
									</div>

									<div>
										<TextField
											name="customer_phonenumber_home_input"
											value={customerState.phoneNumberHome === "-" ? "" : customerState.phoneNumberHome}
											label={`${i18n("customer_information", "phone")} (${i18n("customer_information", "home")})`}
											autocomplete="off"
											validation={Validator.validatePhoneNum}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumberHome: target.value === "" ? "-" : target.value})}
										/>
									</div>
									<div>
										<TextField
											name="customer_phonenumber_alt_input"
											value={customerState.phoneNumberAlternative === "-" ? "" : customerState.phoneNumberAlternative}
											label={`${i18n("customer_information", "phone")} (${i18n("customer_information", "alternative")})`}
											autocomplete="off"
											validation={Validator.validatePhoneNum}
											placeholder={"+46 XX XXXXXX"}
											onChange={({target}) => this.setCustomerState({phoneNumberAlternative: target.value === "" ? "-" : target.value})}
										/>
									</div>

									<div>
										<TextField
											type="email"
											name="customer_email"
											value={customerState.email}
											autocomplete="off"
											label={i18n("general", "email_address")}
											validation={Validator.validateEmail}
											onChange={({target}) => this.setCustomerState({email: target.value})}
											required={true}
										/>
									</div>
								</StackLayout>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>
								<CompanyReps
									reps={customerState.companyReps}
									onRepChange={this._onCompanyRepChange}
									activeAmount={customerState.companyRepsActiveAmount}
									limitedUbo={true}
									withTaxCountry={true}
									fullCountryNameAsValue={true}
									simplified={true}
								/>

								{!this._hasAtLeastOneSignatory(customerState.companyReps) && (
									<div style={{
										padding: "20px",
										background: "#FFECEC",
										borderRadius: "3px",
										maxWidth: "500px",
										margin: "0 0 20px 0"
									}}>
										{i18n("customer_information", "min_one_selected")}
									</div>
								)}

								{customerState.companyRepsActiveAmount <= 6 && (
									<StackLayout fullWidth>
										<Button
											size="small"
											label={i18n("general", "remove_last")}
											appearance={customerState.companyRepsActiveAmount <= 1 ? "medium" : "danger"}
											disabled={customerState.companyRepsActiveAmount <= 1}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount - 1})}
										/>
										<Button
											size="small"
											label={i18n("general", "add")}
											appearance={customerState.companyRepsActiveAmount >= 6 ? "medium" : "primary"}
											disabled={customerState.companyRepsActiveAmount >= 6}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount + 1})}
											filled
										/>
									</StackLayout>
								)}

								<div className="h-line spacing" style={{marginTop: "30px"}}/>

								<IdentityDocument customer={customer} updatedDocument={this._onUpdatedIdentity}/>

							</React.Fragment>
						)}
					</div>
				</ContentBox>

				<input type="hidden" name="customer_social_security_number" value={ssnOrg.replace("-", "")}/>
				<input type="hidden" name="customer_ssn" value={ssnOrg}/>
			</div>
		);
	}

	_onUpdatedIdentity = (valid) =>
	{
		const newState = {...this.state};
		newState.docsFulfilled = valid;
		newState.isFulfilled = this.isFulfilled(newState);
		this.setState(newState, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
		});
	};

	_hasAtLeastOneSignatory(companyReps)
	{
		for (let i = 0; i < this.state.customerState.companyRepsActiveAmount; i++)
		{
			const rep = companyReps[i];
			if (rep.types.length > 0 && (rep.types.includes("signatory") || rep.types.includes("poa")))
			{
				return true;
			}
		}
		return false;
	}

	// Internal methods
	_onCompanyRepChange = (rep, index) =>
	{
		const companyReps = this.state.customerState.companyReps.map((r, i) => i === index ? rep : r);
		this.setCustomerState({companyReps});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		isFulfilled: false,
		isLoading: true,
		lookupNotFound: false,
		fraudFlag: false,
		errorMessage: null,
		docsFulfilled: customer.hasValidIdentityDocument(),

		customerState: {
			isCompany: customer.isCompany,
			date_object: null,
			date: customer.getData("date"),
			ssnOrg: customer.getData("customer_social_security_number") || customer.getData("customer_ssn"),
			firstName: customer.getData("customer_firstname"),
			lastName: customer.getData("customer_lastname"),
			street: customer.getData("customer_address_street"),
			zipCode: customer.getData("customer_address_zipcode"),
			city: customer.getData("customer_address_city"),
			country: customer.getData("customer_address_country") || "Sverige",
			phoneNumber: customer.getData("customer_phonenumber_cell"),
			phoneNumberHome: customer.getData("customer_phonenumber_home"),
			phoneNumberAlternative: customer.getData("customer_phonenumber_alt"),
			email: customer.getData("customer_email"),

			leiCode: customer.getData("customer_lei"),

			companyReps: customer.companyReps,
			companyRepsActiveAmount: Math.max(customer.companyReps.reduce((a, b) => a + (b.firstName ? 1 : 0), 0), 1),

			meetingFormat: customer.getData("radio_meeting_type", null),

			meetingFormatIndex: (
				customer.getData("radio_meeting_type") === "Fysiskt möte" ? 0 :
					customer.getData("radio_meeting_type") === "Digitalt möte" ? 1 :
						customer.getData("radio_meeting_type") === "Telefonmöte" ? 2 :
							-1)
		}
	};

	if (initialState.customerState.date.length > 0)
	{
		initialState.customerState.date_object = Date.parse(initialState.customerState.date);
	}

	return initialState;
}

function _getFields()
{
	return [
		"customer_social_security_number",
		"customer_ssn",
		"customer_firstname",
		"customer_lastname",
		"customer_address_street",
		"customer_address_zipcode",
		"customer_address_city",
		"customer_address_country",
		"customer_address_country_code",
		"customer_citizenship",
		"customer_phonenumber_home",
		"customer_phonenumber_cell",
		"customer_phonenumber_alt",
		"customer_email",
		"customer_lei",

		"account_no_depa",
		"account_no_isk",
		"account_no_kf",
		"account_no_tp",

		// Get company reps
		...(() =>
		{
			const returnValue = [];
			loop(6, i =>
			{
				returnValue.push("checkbox_signatory_" + i);
				returnValue.push("checkbox_ubo_" + i);
				returnValue.push("checkbox_poa_" + i);
				returnValue.push("checkbox_view_" + i);
				returnValue.push("checkbox_mng_" + i);
				returnValue.push("customer_representative_role_" + i);
				returnValue.push("company_representative_role_" + i);
				returnValue.push("company_representative_firstname_" + i);
				returnValue.push("company_representative_lastname_" + i);
				returnValue.push("company_representative_social_security_number_" + i);
				returnValue.push("company_representative_sec_num_1" + i);
				returnValue.push("company_representative_percentage_" + i);
				returnValue.push("company_representative_email_" + i);
				returnValue.push("company_representative_phone_" + i);
				returnValue.push("company_representative_citizenship_" + i);
				returnValue.push("company_representative_tax_country_" + i);
			});
			return returnValue;
		})()
	];
}